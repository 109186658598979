<script lang="ts">
export const enum Steps {
  NONE,
  PERSONAL_QUESTIONS,
  MACHINE_INFORMATION,
  DESCRIPTION_AND_UPLOAD,
  SELECT_REPAIR,
}
</script>

<script setup lang="ts">
import useVuelidate from "@vuelidate/core";
import { customValidators } from "~~/i18n/utils/i18n-validators";
import ContactFormMachineInformation from "~/components/contact/ContactFormMachineInformation.vue";

const { required, requiredIf, email, minLength, maxLength, numeric } =
  customValidators();
const { t } = useI18n();
const { pushError, pushSuccess } = useNotifications();
const { user, isLoggedIn, isGuestSession } = useUser();

const tosAccepted = ref(false);
const loading = ref(false);
const currentStep = ref<Steps>(Steps.PERSONAL_QUESTIONS);

const isInvoiceInFuture = computed(() => {
  const currentDate = new Date();
  const invoiceDate = new Date(stateStep2.invoicedate);
  return invoiceDate > currentDate;
});

const stepTitles = [
  t("form.contactForm.personalData"),
  t("form.contactForm.machineInformation"),
  t("form.contactForm.descriptionAndUpload"),
  t("form.contactForm.selectRepair"),
];

const stateStep1 = reactive({
  title: "", // Test Herr
  customernumber: "",
  name: "",
  street: "",
  zipcode: "", // Test 89335
  city: "",
  countryid: "", // DE
  phone: "",
  mail: "",
  reference: "",
});
const stateStep2 = reactive({
  productnumber: "", // Test 5905211903
  productname: "",
  serial: "",
  serial2: "",
  manufacturingmonth: "",
  manufacturingyear: "",
  invoicedate: "", // Test 2020-12-12
  accessories: "",
});
const stateStep3 = reactive({
  message: "",
  files: [],
});
const stateStep4 = reactive({
  warranty: {
    iswarranty: false,
    files: [],
    retourewarranty: false,
    kvwarranty: false,
  },
  kv: {
    iskv: false,
  },
  repair: {
    isrepair: false,
    maxvalue: "",
  },
  repairwithoutvalue: {
    isrepairwithoutvalue: false,
  },
});

const submitState = {
  isservicecase: true, // Wenn Reklamation
  iscontact: false, // General Question
  isproductquestion: false, // Product Question
  isorderquestion: false, // Order Question
  referencetext: "",
  ...stateStep1,
  product: {
    ...stateStep2,
    ...stateStep3,
  },
  choice: {
    ...stateStep4,
    productrecall: {
      confirmationcheck: false, // Immer False
    },
  },
  altcha: "",
};

const rulesStep1 = computed(() => ({
  title: { required },
  name: { required },
  street: { required },
  zipcode: { required },
  city: { required },
  countryid: { required },
  mail: { required, email },
  phone: { numeric },
}));
const rulesStep2 = computed(() => ({
  productnumber: { required },
  serial: { required, numeric, maxLength: maxLength(4) },
  serial2: {
    required,
    minLength: minLength(4),
    maxLength: maxLength(7),
  },
  manufacturingmonth: {
    required,
    minLength: minLength(1),
    maxLength: maxLength(2),
  },
  manufacturingyear: {
    required,
    minLength: minLength(4),
    maxLength: maxLength(4),
  },
  invoicedate: {
    required,
    not: isInvoiceInFuture.value,
  },
}));
const rulesStep3 = computed(() => ({ message: { required } }));
const rulesStep4 = computed(() => ({
  repair: {
    maxvalue: {
      required: requiredIf(() => {
        return stateStep4.repair.isrepair;
      }),
      numeric,
    },
  },
}));

const v1 = useVuelidate(rulesStep1, stateStep1);
const v2 = useVuelidate(rulesStep2, stateStep2);
const v3 = useVuelidate(rulesStep3, stateStep3);
const v4 = useVuelidate(rulesStep4, stateStep4);

function showErrorMsg(step: number) {
  pushError(
    t("form.contactForm.checkInputsInStep", {
      step: `${t("form.contactForm.step")} ${step}`,
    }),
  );
}

const addressValidationController = useModal();
const addressSuggestions = ref([]);
const addressValidationCompleted = ref(false);
const hasValidationError = ref(false);
// Address Valid Step 1
async function addressValidation() {
  loading.value = true;
  hasValidationError.value = true;

  const response = await fetch(
    `/api/address/address-validation?zipCode=${stateStep1.zipcode}&city=${stateStep1.city}&street=${stateStep1.street}&countryIso=${stateStep1.countryid}`,
  );

  try {
    if (response.status === 200) {
      const responseData = await response.json();
      addressSuggestions.value = responseData;

      if ((addressSuggestions.value?.hno || "") == "") {
        hasValidationError.value = true;
        pushError(t("form.addressValidationErrorMissingHouseNumber"));
        console.error(
          t("form.addressValidationErrorMissingHouseNumber"),
          response.status,
        );
      } else {
        addressValidationController.open();
      }
    } else if (response.status === 204) {
      addressValidationCompleted.value = true;
      return;
    } else {
      hasValidationError.value = true;
      pushError(t("form.addressValidationError"));
      console.error(t("form.addressValidationError"), response.status);
    }
  } finally {
    loading.value = false;
  }
}

async function assigneStateAddress() {
  Object.assign(submitState, stateStep1);
  currentStep.value++;
  addressValidationCompleted.value = false;
  hasValidationError.value = false;
}

const { WriteMessageToGraylog } = useGrayLog();

async function nextStep() {
  let isValid = true;

  if (isValid) {
    switch (currentStep.value) {
      // Step 1
      case Steps.PERSONAL_QUESTIONS:
        v1.value.$touch();
        isValid = await v1.value.$validate();
        if (isValid) {
          // Address Valid Step 3
          if (!addressValidationCompleted.value) {
            await addressValidation();
          }
          // Address Valid Step 4
          if (isValid && addressValidationCompleted.value) {
            await assigneStateAddress();
          }
          break;
        } else {
          showErrorMsg(currentStep.value);
        }

      // Step 2
      case Steps.MACHINE_INFORMATION:
        v2.value.$touch();
        isValid = await v2.value.$validate();

        if (isInvoiceInFuture.value) {
          pushError(t("form.futureInvoiceDate"));
          return;
        }

        if (!machineInformationRef?.value?.isProductNumberValid) return;

        if (isValid) {
          Object.assign(submitState.product, stateStep2);
          currentStep.value++;
        } else {
          showErrorMsg(currentStep.value);
        }
        break;

      // Step 3
      case Steps.DESCRIPTION_AND_UPLOAD:
        v3.value.$touch();
        isValid = await v3.value.$validate();
        if (isValid) {
          Object.assign(submitState.product, stateStep3);
          currentStep.value++;
        } else {
          showErrorMsg(currentStep.value);
        }
        break;

      // Step 4
      case Steps.SELECT_REPAIR:
        v4.value.$touch();
        isValid = await v4.value.$validate();
        if (isValid) {
          Object.assign(submitState.choice, stateStep4);

          // Tos not accepted
          if (!tosAccepted.value) {
            pushError(t("checkout.termsAndConditionsError"));
            console.error("Error: TOS not Accecpted.");
            await WriteMessageToGraylog("TOS not Accecpted.", stateStep1.mail);
            return;
          }

          // Missing Captcha
          if (!submitState.altcha) {
            pushError(t("form.invalidCaptcha"));
            console.error("Error: Missing Captcha.");
            return;
          }

          let response: Response | null = null;

          try {
            loading.value = true;
            response = await fetch("/api/contact/contactForm", {
              method: "POST",
              body: JSON.stringify(submitState),
              headers: { "Content-Type": "application/json" },
            });

            if (response.status === 200) {
              pushSuccess(t("form.contactForm.submitSuccessfull"));
              navigateTo("/contact");
            } else if (response.status === 413) {
              pushError(t("form.payoadTooLarge"));
            } else {
              pushError(t("form.contactForm.submitError"));
              console.error(t("form.contactForm.submitError"), response.status);
            }
          } catch (error) {
            console.error("An error occurred:", error);
            const status = response?.status ?? "No response received";
            await WriteMessageToGraylog(
              `Fetch failed (/api/contact/contactForm): ${error} - Response Status: ${status}`,
              stateStep1.mail,
            );
          } finally {
            loading.value = false;
          }
        } else {
          pushError(t("checkout.termsAndConditionsError"));
        }
        break;

      default:
        break;
    }
  }
}

// Address Valid Step 2
const toggleValidationComplete = () => {
  addressValidationCompleted.value = !addressValidationCompleted.value;
  nextStep();
};

function prevStep() {
  if (currentStep.value > Steps.PERSONAL_QUESTIONS) currentStep.value--;
}

function fillUserInformation() {
  let productInformation = JSON.parse(
    localStorage.getItem("productClaim") as string,
  );
  const date = new Date(productInformation.invoicedate);

  // Step 1
  stateStep1.title = `${user?.value?.salutation?.displayName}` || "";
  stateStep1.name = `${user?.value?.firstName} ${user?.value?.lastName}` || "";
  stateStep1.street = `${user.value?.defaultBillingAddress?.street}` || "";
  stateStep1.city = `${user.value?.defaultBillingAddress?.city}` || "";
  stateStep1.zipcode = `${user.value?.defaultBillingAddress?.zipcode}` || "";
  stateStep1.countryid =
    `${user.value?.defaultBillingAddress?.country?.name}` || "";
  stateStep1.mail = `${user.value?.email}` || "";

  // Step 2
  stateStep2.productnumber = window.location.hash.slice(1) || "";
  stateStep2.productname = productInformation.productname || "";
  stateStep2.invoicedate = date.toISOString().split("T")[0] || "";
}

const machineInformationRef = ref<typeof ContactFormMachineInformation>();

onMounted(() => {
  v1.value.$reset();
  v2.value.$reset();
  v3.value.$reset();
  v4.value.$reset();

  if (
    window.location.hash.slice(1) &&
    (isLoggedIn.value || isGuestSession.value)
  ) {
    fillUserInformation();
  }
});

const { width: windowWidth } = useWindowSize();
const isMobile = computed(() => (windowWidth.value ?? 0) < 1024);
</script>

<template>
  <div class="m-5 xl:max-w-[1480px] xl:px-5 xl:mx-auto">
    <div class="bg-white py-5">
      <SharedStepIndicator
        class="mx-5 mb-20"
        :current-step="currentStep - 1"
        :num-steps="4"
        :step-titles="stepTitles"
        :mobile-hidelabel="true"
      />
      <div class="flex flex-col lg:flex-row gap-10 xl:gap-5 relative">
        <div
          class="flex flex-col flex-wrap gap-y-3 order-2 lg:order-1 w-full lg:w-1/2"
        >
          <ScheppachSpinner v-if="loading" />

          <template v-if="currentStep === Steps.PERSONAL_QUESTIONS">
            <ContactFormPersonalData :state="stateStep1" :rules="rulesStep1" />
            <SharedWarningBox
              v-if="hasValidationError"
              class="my-5"
              :text="t('form.addressValidationWarningTextFormular')"
              :callback="assigneStateAddress"
            />
          </template>

          <template v-if="currentStep === Steps.MACHINE_INFORMATION">
            <ContactFormMachineInformation
              ref="machineInformationRef"
              :state="stateStep2"
              :rules="rulesStep2"
              :is-invoice-in-future="isInvoiceInFuture"
            />
          </template>

          <template v-if="currentStep === Steps.DESCRIPTION_AND_UPLOAD">
            <ContactFormDescriptionUpload
              :state="stateStep3"
              :rules="rulesStep3"
            />
          </template>
          <template v-if="currentStep === Steps.SELECT_REPAIR">
            <ContactFormSelectRepair :state="stateStep4" :rules="rulesStep4" />
          </template>
        </div>

        <div class="xl:mx-auto xl:px-10 order-1 xl:order-2 w-full lg:w-1/2">
          <ContactFormStepInfoBox
            :current-step="currentStep"
            :state="stateStep4"
          />

          <p class="c-scheppach-primary-700 mt-3">
            {{ t("form.contactForm.indexTextBottomInfo") }}
          </p>
          <NuxtLink
            :to="'/spare-parts'"
            target="_blank"
            class="c-scheppach-primary-700 underline underline-offset-5 font-bold block text-center mt-5"
          >
            {{ t("form.contactForm.gotoProductFaq") }}
          </NuxtLink>

          <AltchaCaptcha
            v-if="!isMobile && currentStep == Steps.SELECT_REPAIR"
            v-model:payload="submitState.altcha"
            class="my-5"
          />

          <ScheppachCheckbox
            v-if="currentStep == Steps.SELECT_REPAIR"
            v-model="tosAccepted"
            class="mt-5 rd hidden lg:block"
            :label="t('form.privacy')"
            :required="true"
          />

          <div
            class="hidden lg:flex flex-col sm:flex-row gap-3 text-center mt-5"
          >
            <button
              class="w-full sm:w-55 cursor-pointer rd px-3 py-2 inline-block select-none"
              :disabled="currentStep === Steps.PERSONAL_QUESTIONS"
              :class="[
                currentStep === Steps.PERSONAL_QUESTIONS
                  ? 'b b-scheppach-neutral-300 cursor-not-allowed bg-scheppach-neutral-50 c-gray'
                  : 'b b-scheppach-primary-500 c-scheppach-primary-700 bg-white hover:bg-scheppach-primary-50',
              ]"
              @click="prevStep"
            >
              {{ t("form.contactForm.back") }}
            </button>
            <div
              class="w-full sm:w-55 rd px-3 py-2 select-none c-white"
              :disabled="!tosAccepted && currentStep == Steps.SELECT_REPAIR"
              :class="[
                !tosAccepted && currentStep == Steps.SELECT_REPAIR
                  ? 'cursor-not-allowed bg-scheppach-neutral-300'
                  : 'cursor-pointer bg-scheppach-primary-500 hover:bg-scheppach-primary-700',
              ]"
              @click="nextStep"
            >
              {{
                currentStep !== Steps.SELECT_REPAIR
                  ? t("form.contactForm.continue")
                  : t("form.contactForm.submit")
              }}
            </div>
          </div>
        </div>
      </div>

      <AltchaCaptcha
        v-if="isMobile && currentStep == Steps.SELECT_REPAIR"
        v-model:payload="submitState.altcha"
        class="my-5"
      />

      <ScheppachCheckbox
        v-if="currentStep == Steps.SELECT_REPAIR"
        v-model="tosAccepted"
        class="mt-5 rd block lg:hidden"
        :label="t('form.privacy')"
        :required="true"
      />

      <div class="flex lg:hidden flex-col sm:flex-row gap-3 text-center mt-5">
        <button
          class="w-full sm:w-55 cursor-pointer rd px-3 py-2 inline-block select-none"
          :disabled="currentStep === Steps.PERSONAL_QUESTIONS"
          :class="[
            currentStep === Steps.PERSONAL_QUESTIONS
              ? 'b b-scheppach-neutral-300 cursor-not-allowed bg-scheppach-neutral-50 c-gray'
              : 'b b-scheppach-primary-500 c-scheppach-primary-700 bg-white hover:bg-scheppach-primary-50',
          ]"
          @click="prevStep"
        >
          {{ t("form.contactForm.back") }}
        </button>
        <div
          class="w-full sm:w-55 rd px-3 py-2 select-none c-white"
          :disabled="!tosAccepted && currentStep == Steps.SELECT_REPAIR"
          :class="[
            !tosAccepted && currentStep == Steps.SELECT_REPAIR
              ? 'cursor-not-allowed bg-scheppach-neutral-300'
              : 'cursor-pointer bg-scheppach-primary-500 hover:bg-scheppach-primary-700',
          ]"
          @click="nextStep"
        >
          {{
            currentStep !== Steps.SELECT_REPAIR
              ? t("form.contactForm.continue")
              : t("form.contactForm.submit")
          }}
        </div>
      </div>
    </div>

    <NuxtLink
      :to="'/contact'"
      class="w-full md:w-1/4 lg:w-200px mt-5 lg:mt-0 cursor-pointer text-center b b-scheppach-primary-500 c-scheppach-primary-500 hover:bg-scheppach-primary-50 duration-150 rd px-3 py-2 inline-block uppercase"
    >
      {{ t("goBack") }}
    </NuxtLink>
  </div>

  <!-- Address Valid Step 4 -->
  <SharedModal :controller="addressValidationController">
    <ScheppachAddressValidation
      :state="stateStep1"
      :controller="addressValidationController"
      :require-country-name="true"
      :address-suggestions="addressSuggestions"
      @toggle-validation-complete="toggleValidationComplete"
    />
  </SharedModal>
</template>
