<script setup lang="ts">
import { Steps } from "../../pages/contact/claim-form.vue";
const { t } = useI18n();
const controller = useModal();

const props = defineProps<{
  state?: {
    warranty: {
      iswarranty: boolean;
      files: string[];
      retourewarranty: boolean;
      kvwarranty: boolean;
    };
    kv: {
      iskv: boolean;
    };
    repair: {
      isrepair: boolean;
      maxvalue: string;
    };
    repairwithoutvalue: {
      isrepairwithoutvalue: boolean;
    };
  };
  currentStep?: number;
  title?: string;
  content?: string;
}>();
const { currentStep, state } = toRefs(props);
</script>

<template>
  <div
    v-if="currentStep != null"
    class="bg-scheppach-primary-50 b b-scheppach-primary-500 p-5 c-scheppach-primary-500 rd mb-5"
  >
    <h2 class="p-0 text-2xl sm:text-3xl mb-5">
      {{ t("form.contactForm.complaintRequest") }}
    </h2>
    <div class="font-500">
      <div v-if="currentStep === Steps.PERSONAL_QUESTIONS">
        <h5 class="mb-2">
          {{ t("form.contactForm.step", { step: 1 }) }}:
          {{ t("form.contactForm.personalData") }}
        </h5>
        <p>
          {{ t("form.contactForm.personalDataText") }}
        </p>
        <p class="mt-5">
          {{ t("form.contactForm.personalDataSmallText") }}
          <NuxtLink
            target="_blank"
            class="font-700 underline underline-offset-2 ml-1 capitalize"
            :to="`/${$t('footer.data-protection')}`"
            >{{ t("checkout.here") }}</NuxtLink
          >
        </p>
      </div>

      <div v-else-if="currentStep === Steps.MACHINE_INFORMATION">
        <h5 class="mb-2">
          {{ t("form.contactForm.step", { step: 2 }) }}:
          {{ t("form.contactForm.machineInformation") }}
        </h5>
        <p>
          {{ t("form.contactForm.machineInformationText") }}
        </p>
        <p class="mt-5">
          <NuxtLink
            target="_blank"
            class="font-700 underline underline-offset-2 capitalize cursor-pointer"
            @click="controller.open"
            >{{ t("checkout.here") }}</NuxtLink
          >
          {{ t("form.contactForm.machineInformationSmallText") }}
        </p>
      </div>

      <div v-else-if="currentStep === Steps.DESCRIPTION_AND_UPLOAD">
        <h5 class="mb-2">
          {{ t("form.contactForm.step", { step: 3 }) }}:
          {{ t("form.contactForm.descriptionAndUpload") }}
        </h5>
        <p>
          {{ t("form.contactForm.descriptionAndUploadText") }}
        </p>
        <br />
        <p>
          {{ t("form.contactForm.descriptionAndUploadSmallText") }}
        </p>
      </div>

      <div v-else-if="currentStep === Steps.SELECT_REPAIR">
        <h5 class="mb-2">
          {{ t("form.contactForm.step", { step: 4 }) }}:
          {{ t("form.contactForm.selectRepair") }}
        </h5>
        <p v-if="state?.warranty.iswarranty">
          {{ t("form.contactForm.selectRepairText1") }}
        </p>
        <p v-else-if="state?.kv.iskv">
          {{ t("form.contactForm.selectRepairText2") }}
        </p>
        <p v-else-if="state?.repair.isrepair">
          {{ t("form.contactForm.selectRepairText3") }}
        </p>
        <p v-else-if="state?.repairwithoutvalue.isrepairwithoutvalue">
          {{ t("form.contactForm.selectRepairText4") }}
        </p>
      </div>
    </div>
  </div>
  <div
    v-else
    class="bg-scheppach-primary-50 b b-scheppach-primary-500 p-5 c-scheppach-primary-500 rd mb-5"
  >
    <h2 class="p-0 text-2xl sm:text-3xl mb-5">
      {{ title }}
    </h2>
    <div class="font-500">
      {{ content }}
    </div>
  </div>

  <SharedModal :controller="controller">
    <SharedProductInformationExpand />
  </SharedModal>
</template>
